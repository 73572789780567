.mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .text-center {
    text-align: center;
  }
  
  .h2 {
    font-size: 2rem;
  }
  
  .accordions {
    max-width: 100%;
    margin: auto;
  }
  
  .accordion .accordion__title {
    display: block;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 700;
    background-color: var(--white);
    border-bottom: 1px solid #0002;
    padding: 20px 10px;
    color: var(--dark);
    margin-top: 10px;
    transition: all 0.25s ease;
    cursor: pointer;
  }
  
  .accordion .accordion__title input {
    appearance: none;
  }
  
  .accordion .accordion__title:after {
    transition: all 0.25s ease;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0da";
    float: right;
    margin-right: 10px;
  }
  
  .accordion .accordion__title:has(input:checked):after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0d7";
    float: right;
    margin-right: 10px;
  }
  
  .accordion .accordion__text {
    overflow: hidden;
    max-height: 0;
    background-color: var(--gray-light);
    filter: contrast(.9);
    transition: all .5s ease-in-out;
    font-weight: 400;
  }
  
  .accordion .accordion__text p {
    font-weight: 100;
    line-height: 1.5rem;
    padding: 10px 10px;
    color: var(--gray-dark);  
  }
  
  .accordion .accordion__title:has(input:checked) {
    color: var(--green-light);
  }
  
  .accordion .accordion__title:has(input:checked) + .accordion__text {
    max-height: 200px;
  }
  
  .mt-2 {
    margin-top: 20px;
  }
  
  .my-1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .btn-coffee {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: inline-block;
    background-color: green;
    text-transform: uppercase;
    color: white;
    border: 1px solid #0001;
    border-radius: 10px;
    padding: 20px;
    font-weight: 600;
    box-shadow: 2px 2px 6px #0009;
    text-shadow: 1px 1px #0001;
    text-decoration: none;
    transition: all 0.25s ease;
    animation: live 0.6s infinite alternate;
    &:hover {
      background-color: olive;
    }
  }
  
  
  @keyframes live {
    0% {
      scale: .7;
    }
    100% {
      scale: .72;
    }
  }